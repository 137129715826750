import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import {
  AppBody,
  Title,
  Content,
  headerImageStyle,
  ContentContainer,
} from "../components/common"
import Img from "gatsby-image"
import BkgImg from "gatsby-background-image"
import SEO from "../components/seo"

const Shippers = ({
  data: {
    headerImage,
    markdownRemark: { html: __html },
  },
}) => {
  return (
    <Layout>
      <SEO title="Shippers" />
      <AppBody>
        <BkgImg
          fluid={headerImage.childImageSharp.fluid}
          style={headerImageStyle}
        >
          <Title>Shippers</Title>
        </BkgImg>
        <ContentContainer>
          <Content dangerouslySetInnerHTML={{ __html }} />
        </ContentContainer>
      </AppBody>
    </Layout>
  )
}

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "truck3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: "/shippers" } }) {
      html
    }
  }
`
export default Shippers
